<template>
    <div>
        <v-card class="rounded-lg" elevation="0" max-height="190px">
            <div class="thumbnail-container">
                <iframe
                    :src="`https://www.youtube.com/embed/${youtubeID}`"
                    onload="this.width=screen.width;this.height=screen.height;"
                    title="YouTube video player"
                    frameborder="0"
                    style="width: 100%; height: 190px"
                    class="rounded-lg"
                >
                </iframe>
            </div>
        </v-card>

        <v-list-item three-line>
            <v-list-item-content>
                <v-list-item-title
                    class="font-weight-bold success--text mb-2 c-pointer"
                    @click="$emit('onViewInformation')"
                >
                    {{ title }}
                </v-list-item-title>
                <v-list-item-subtitle class="secondary--text caption mb-2">{{
                    formattedScheduleDate
                }}</v-list-item-subtitle>
                <v-list-item-subtitle>
                    <template v-for="(tag, index) in tags">
                        <v-chip
                            class="font-weight-bold secondary mr-2"
                            small
                            dark
                            :key="index"
                        >
                            {{ tag }}
                        </v-chip>
                    </template>
                </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
                <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                            @click="openAlertDialog"
                        >
                            <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                    </template>
                    <span>Delete Video</span>
                </v-tooltip>
            </v-list-item-action>
        </v-list-item>

        <base-alert-dialog
            :is-open.sync="alertDialog.open"
            :title="alertDialog.title"
            :description="alertDialog.description"
            :theme="alertDialog.theme"
            :loading="isDeleteStart"
            @onCancel="alertDialog.open = false"
            @onProceed="deleteVideo"
        ></base-alert-dialog>
    </div>
</template>

<script>
import dateUtility from "@/mixins/date";
import helperUtility from "@/mixins/helper";
import { WATCH_DELETE_VIDEO } from "@/store/modules/watch";
import BaseAlertDialog from "@/components/Base/BaseAlertDialog";

export default {
    name: "watch-video-preview-card",

    components: { BaseAlertDialog },

    props: {
        videoID: Number,
        title: String,
        schedule: String,
        youtubeLink: String,
        featuredImage: String,
        category: Object,
        language: Object,
    },

    data() {
        return {
            isDeleteStart: false,
            alertDialog: {
                open: false,
                theme: "warning",
                title: null,
                description: null,
            },
        };
    },

    computed: {
        formattedScheduleDate() {
            return dateUtility.formatTimestampToSchedule(
                dateUtility.toLocalDate(this.schedule)
            );
        },

        youtubeID() {
            if (!this.youtubeLink) {
                return null;
            }

            return helperUtility.getYoutubeIDFromURL(this.youtubeLink);
        },

        tags() {
            const tags = [];
            if (this.category) {
                tags.push(this.category.name);
            }
            if (this.language) {
                tags.push(this.language.name);
            }

            return tags;
        },
    },

    methods: {
        async deleteVideo() {
            this.isDeleteStart = true;
            const { success } = await this.$store.dispatch(
                WATCH_DELETE_VIDEO,
                this.videoID
            );
            if (success) {
                this.alertDialog.open = false;
                this.isDeleteStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "error",
                    duration: 5000,
                    title: "Watch",
                    text: "Video was deleted.",
                });
                this.$emit("onDeleteSuccess");
            }
            this.isDeleteStart = false;
        },

        openAlertDialog() {
            this.alertDialog = Object.assign(
                {},
                {
                    ...this.alertDialog,
                    title: "Delete Video",
                    description: `Are you sure you want to delete ${this.title}? Click proceed if you are sure.`,
                    open: true,
                }
            );
        },
    },
};
</script>

<style lang="scss" scoped>
.thumbnail-container {
    position: relative;
    width: 100%;
    height: 190px;
}

.thumbnail {
    position: absolute;
    width: 100%;
    height: 100%;
    border: none;
}
</style>
