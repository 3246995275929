<template>
    <base-dialog width="840" :title="dynamicTitles.card" :is-open.sync="dialog">
        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="pt-7 px-2">
            <v-container fluid>
                <v-row>
                    <v-col cols="12">
                        <base-input-label required
                            >Youtube Link</base-input-label
                        >
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.youtube_link"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Language</base-input-label>
                        <v-select
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            append-icon="mdi-chevron-down"
                            hide-details
                            color="secondary"
                            :items="languages"
                            item-value="id"
                            item-text="name"
                            v-model="form.language_id"
                        >
                        </v-select>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label required>Title</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.title"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label optional>Speaker</base-input-label>
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.speaker"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label
                            optional
                            hint="e.g. Genesis 1:1-10; Nehemiah 7:2-30"
                            >Scripture</base-input-label
                        >
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.scripture"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label optional
                            >Bulletin URL</base-input-label
                        >
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.bulletin_url"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label optional
                            >Study Guide URL</base-input-label
                        >
                        <v-text-field
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.study_guide_url"
                        ></v-text-field>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label optional
                            >Description</base-input-label
                        >
                        <v-textarea
                            solo
                            rounded
                            class="rounded-lg"
                            flat
                            hide-details
                            v-model="form.description"
                        ></v-textarea>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12">
                                <base-input-label required
                                    >Select Playlist</base-input-label
                                >
                                <v-select
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    append-icon="mdi-chevron-down"
                                    hide-details
                                    color="secondary"
                                    :items="playlists"
                                    item-value="id"
                                    item-text="name"
                                    v-model="form.video_category_id"
                                ></v-select>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="12">
                                <base-input-label optional
                                    >Visibility</base-input-label
                                >
                                <v-checkbox
                                    color="secondary"
                                    hide-details
                                    v-model="form.featured_on_front_page"
                                >
                                    <template v-slot:label>
                                        <div
                                            class="text-uppercase subtitle-2 font-weight-bold"
                                        >
                                            Featured on front page
                                        </div>
                                    </template>
                                </v-checkbox>
                                <!-- <v-checkbox
                                    color="secondary"
                                    hide-details
                                    v-model="form.exclude_to_listing"
                                >
                                    <template v-slot:label>
                                        <div
                                            class="text-uppercase subtitle-2 font-weight-bold"
                                        >
                                            Exclude
                                        </div>
                                    </template>
                                </v-checkbox> -->
                                <div class="live-div">
                                    <v-checkbox
                                        color="secondary"
                                        hide-details
                                        v-model="form.live_on_watch_page"
                                    >
                                        <template v-slot:label>
                                            <div
                                                class="text-uppercase subtitle-2 font-weight-bold"
                                            >
                                                Live on watch page
                                            </div>
                                        </template>
                                    </v-checkbox>
                                    <div class="d-flex live-picker-div">
                                        <base-datetime-picker
                                            :disabled="!form.live_on_watch_page"
                                            solo
                                            rounded
                                            custom-class="rounded-lg"
                                            flat
                                            hide-details
                                            color="secondary"
                                            :min="dateTimePickerMinDate"
                                            v-model="form.live_from"
                                            label="Live From"
                                        ></base-datetime-picker>
                                        <base-datetime-picker
                                            :disabled="!form.live_on_watch_page"
                                            solo
                                            rounded
                                            custom-class="rounded-lg"
                                            flat
                                            hide-details
                                            color="secondary"
                                            :min="dateTimePickerMinDate"
                                            label="Live to"
                                            v-model="form.live_to"
                                        ></base-datetime-picker>
                                    </div>
                                </div>
                            </v-col>

                            <v-col cols="12" md="12">
                                <v-row>
                                    <v-col cols="12">
                                        <base-input-label optional
                                            >Schedule At</base-input-label
                                        >
                                        <base-datetime-picker
                                            solo
                                            rounded
                                            custom-class="rounded-lg"
                                            flat
                                            hide-details
                                            color="secondary"
                                            v-model="form.schedule_at"
                                        ></base-datetime-picker>
                                    </v-col>

                                    <v-col cols="12">
                                        <base-input-label optional
                                            >Post Expiration</base-input-label
                                        >
                                        <v-checkbox
                                            color="secondary"
                                            v-model="form.post_expiration"
                                        >
                                            <template v-slot:label>
                                                <div
                                                    class="text-uppercase subtitle-2 font-weight-bold"
                                                >
                                                    Enable?
                                                </div>
                                            </template>
                                        </v-checkbox>

                                        <base-datetime-picker
                                            solo
                                            rounded
                                            custom-class="rounded-lg"
                                            flat
                                            hide-details
                                            color="secondary"
                                            :disabled="!form.post_expiration"
                                            :min="dateTimePickerMinDate"
                                            v-model="form.expiration_date"
                                        ></base-datetime-picker>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12">
                        <base-input-label optional>{{
                            dynamicTitles.featuredImage
                        }}</base-input-label>

                        <div class="d-flex justify-space-between align-center">
                            <v-file-input
                                solo
                                rounded
                                :prepend-icon="null"
                                show-size
                                small-chips
                                color="secondary"
                                clearable
                                flat
                                hide-details
                                accept="image/*"
                                class="rounded-lg"
                                id="featured-image"
                                v-model="form.featured_image"
                            >
                                <template v-slot:selection="{ text }">
                                    <v-chip color="secondary" small label>
                                        {{ text }}
                                    </v-chip>
                                </template>
                            </v-file-input>
                            <div class="mx-2"></div>
                            <v-btn
                                class="caption font-weight-bold text-capitalize"
                                small
                                depressed
                                rounded
                                dark
                                color="secondary"
                                @click="openFileInput('featured-image')"
                                >Select File</v-btn
                            >
                        </div>

                        <v-menu
                            max-width="20rem"
                            top
                            v-if="
                                operation.editMode &&
                                form.existing_featured_image
                            "
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <div class="pt-2">
                                    <span
                                        class="caption text-decoration-underline secondary--text c-pointer"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        View existing image
                                    </span>
                                </div>
                            </template>
                            <v-card width="20rem">
                                <v-img
                                    :src="form.existing_featured_image"
                                    width="100%"
                                    height="auto"
                                ></v-img>
                            </v-card>
                        </v-menu>
                    </v-col>

                    <v-col cols="12">
                        <v-row>
                            <v-col cols="12" md="6">
                                <base-input-label optional
                                    >Video Series</base-input-label
                                >
                                <v-combobox
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    hide-details
                                    multiple
                                    small-chips
                                    append-icon=""
                                    item-text="name"
                                    v-model="form.video_series"
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip
                                            class="white--text"
                                            color="secondary"
                                            small
                                            :key="JSON.stringify(item)"
                                            >{{ item.name || item }}</v-chip
                                        >
                                    </template>
                                </v-combobox>
                            </v-col>

                            <v-col cols="12" md="6">
                                <base-input-label optional
                                    >Video Topics</base-input-label
                                >
                                <v-combobox
                                    solo
                                    rounded
                                    class="rounded-lg"
                                    flat
                                    hide-details
                                    multiple
                                    small-chips
                                    append-icon=""
                                    v-model="form.video_topics"
                                >
                                    <template v-slot:selection="{ item }">
                                        <v-chip
                                            class="white--text"
                                            color="secondary"
                                            small
                                            :key="JSON.stringify(item)"
                                            >{{ item.name || item }}</v-chip
                                        >
                                    </template>
                                </v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import BaseInputLabel from "@/components/Base/BaseInputLabel";
import BaseDatetimePicker from "@/components/Base/BaseDateTimePicker";
import { WATCH_CREATE_VIDEO, WATCH_UPDATE_VIDEO } from "@/store/modules/watch";
import dateUtility from "@/mixins/date";
import helperUtility from "@/mixins/helper";
import BaseDialog from "@/components/Base/BaseDialog";
import moment from "moment"

const defaultForm = {
    video_category_id: null,
    title: null,
    description: null,
    youtube_link: null,
    schedule_at: null,
    speaker: null,
    scripture: null,
    featured_on_front_page: null,
    live_on_watch_page: null,
    exclude_to_listing: null,
    bulletin_url: null,
    study_guide_url: null,
    language_id: null,
    post_expiration: null,
    live_from: null,
    live_to: null,
    expiration_date: null,
    featured_image: null,
    existing_featured_image: null,
    home_thumbnail: null,
    small_thumbnail_alternate: null,
    video_series: [],
    video_topics: [],
};

export default {
    name: "watch-video-form-dialog",

    components: { BaseDialog, BaseDatetimePicker, BaseInputLabel },

    props: {
        show: { type: Boolean, default: false, required: true },
        action: { type: String, default: "", required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            error: null,
            isActionStart: false,
            form: Object.assign({}, defaultForm),
            addedVideo: null,
        };
    },

    computed: {
        playlists() {
            return this.$store.state.selection.playlists;
        },

        languages() {
            return this.$store.state.selection.languages;
        },

        dateTimePickerMinDate() {
            return dateUtility.currentDate();
        },

        operation() {
            return {
                createMode: this.action === "add",
                editMode: this.action === "edit",
            };
        },

        dynamicTitles() {
            let title = {
                card: null,
                featuredImage: null,
            };
            if (this.operation.createMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Add Video",
                        featuredImage: "Featured Image",
                    }
                );
            } else if (this.operation.editMode) {
                title = Object.assign(
                    {},
                    {
                        card: "Edit Video",
                        featuredImage: "Change Featured Image",
                    }
                );
            }

            return title;
        },
    },

    watch: {
        show(value) {
            this.dialog = value;
        },

        data(value) {
            if (
                this.action === "edit" &&
                this.data &&
                Object.keys(value).length > 0
            ) {
                const { schedule_at, expiration_date } = value;
                this.form = Object.assign(
                    {},
                    {
                        ...this.form,
                        ...value,
                        id: value.id,
                        schedule_at: schedule_at
                            ? dateUtility.toLocalDate(schedule_at)
                            : null,
                        expiration_date: expiration_date
                            ? dateUtility.toLocalDate(expiration_date)
                            : null,
                    }
                );
            }
        },

        dialog(value) {
            if (!value && this.operation.createMode) {
                this.resetForm();
                this.$emit("close");
            } else if (!value && this.operation.editMode) {
                this.$emit("close");
                this.resetForm();
            }
        },

        "form.post_expiration"(value) {
            if (!value) {
                this.form.expiration_date = null;
            }
        },

        "form.live_on_watch_page"(value) {
            if (!value) {
                this.form.live_from = null;
                this.form.live_to = null;
            }
        },

        "form.youtube_link"(value) {
            if (value && value.includes("youtu.be")) {
                this.$nextTick(() => {
                    const id = helperUtility.getYoutubeIDFromURL(value);
                    this.form.youtube_link = `https://www.youtube.com/watch?v=${id}`;
                });
            }
        },
    },

    methods: {
        openFileInput(elementId) {
            this.$nextTick(() => {
                document.getElementById(elementId).click();
            });
        },

        async onSave() {
            if (this.operation.createMode) {
                await this.onCreate();
            } else if (this.operation.editMode) {
                await this.onUpdate();
            }
        },

        async onCreate() {
            const home_thumbnail = this.form.youtube_link
                ? `http://img.youtube.com/vi/${helperUtility.getYoutubeIDFromURL(
                      this.form.youtube_link
                  )}/maxresdefault.jpg`
                : null;
            const payload = {
                ...this.form,
                schedule_at: this.form.schedule_at
                    ? dateUtility.toUTC(this.form.schedule_at)
                    : null,
                live_from: moment(this.form.live_from).utc().format('YYYY-MM-DD hh:mm:ss'),
                live_to: moment(this.form.live_to).utc().format('YYYY-MM-DD hh:mm:ss'),
                expiration_date: this.form.expiration_date
                    ? dateUtility.toUTC(this.form.expiration_date)
                    : null,
                home_thumbnail,
            };

            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                WATCH_CREATE_VIDEO,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Watch",
                    text: "Video was created.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        async onUpdate() {
            const home_thumbnail = this.form.youtube_link
                ? `http://img.youtube.com/vi/${helperUtility.getYoutubeIDFromURL(
                      this.form.youtube_link
                  )}/maxresdefault.jpg`
                : null;
            const payload = {
                id: this.form.id,
                ...this.form,
                home_thumbnail,
                live_from: moment(this.form.live_from).utc().format('YYYY-MM-DD hh:mm:ss'),
                live_to: moment(this.form.live_to).utc().format('YYYY-MM-DD hh:mm:ss'),
                schedule_at: this.form.schedule_at
                    ? dateUtility.toUTC(this.form.schedule_at)
                    : null,
                expiration_date: this.form.expiration_date
                    ? dateUtility.toUTC(this.form.expiration_date)
                    : null,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                WATCH_UPDATE_VIDEO,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Watch",
                    text: "Video was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },
    },
};
</script>

<style scoped>
.live-div {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.live-picker-div {
    gap: 20px;
}
</style>
